/* landing.css */

/* Apply styles to the body and the video container */
body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    /* Hide overflow to prevent scrollbars */
}

.video-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    /* Place the video behind other content */
    overflow: hidden;
}

/* Style the video element */
video {
    object-fit: cover;
    /* Cover the entire viewport */
    width: 100%;
    height: 100%;
    pointer-events: none;
    /* Disable pointer events on the video */
}